import React from 'react';
import { CloseButton } from '../../../assets';
import { useSelector } from 'react-redux';

import {
  FaqIcon,
  LearnIcon,
  SquadIcon,
  BadgesIcon,
  ActivityIcon,
} from '../../../assets';
import { Link } from 'react-router-dom';

const navbarItems = [
  { name: 'My Activies', img: <ActivityIcon.default />, link: '/' },
  { name: 'Learn by category', img: <LearnIcon.default />, link: '/' },
  { name: 'My squads', img: <SquadIcon.default />, link: '/' },
  { name: 'My badges', img: <BadgesIcon.default />, link: '/' },
  { name: 'Help desk', img: <FaqIcon.default />, link: '/' },
];

const Sidebar = ({ isOpen, onClose }) => {
  const learnerDetail = useSelector((state) => state.learnerDetail);
  console.log(learnerDetail, 'this is values');
  return (
    <div className={`sidebar ${isOpen ? 'open' : ''}`}>
      <div className='overlay' onClick={onClose}></div>
      <div className='sidebar-content  d-block d-sm-none'>
        <button className='close-button' onClick={onClose}>
          <CloseButton.default />
        </button>
        <div className='nav-user-details'>
          <div className='user-details d-flex'>
            <img className='' src={learnerDetail?.profileImage} alt='' />
            <div className='details'>
              <h6>{learnerDetail?.name}</h6>
              <div className='d-flex'>
                <p>
                  {learnerDetail?.points} points <span>|</span>
                </p>{' '}
                <p>
                  {learnerDetail &&
                  learnerDetail?.rank &&
                  learnerDetail?.rank === 1
                    ? '1 st'
                    : learnerDetail?.rank === 2
                    ? '2 nd'
                    : learnerDetail?.rank === 3
                    ? '3 rd'
                    : `${learnerDetail?.rank} th`}{' '}
                  rank
                </p>
              </div>
            </div>
          </div>
        </div>
        <ul className='sidebar-menu p-4'>
          {navbarItems.map((item) => {
            return (
              <li className='mb-1'>
                <Link>
                  {item.img} {item.name}
                </Link>
              </li>
            );
          })}
        </ul>
      </div>
    </div>
  );
};

export default Sidebar;
