import React, { useState, useEffect } from 'react';
import Header from '../../components/Common/Header/Header';
import Footer from '../../components/Common/Footer/Footer';
import TopicCategoryDetail from '../../components/Learn/Category/TopicCategoryDetail';
import {
  getTopiDetilsStats,
  getTopicFlashCards,
  getTopicWiseDecks,
  startLearingQuizExam,
  getLearnTakingTestNow,
  getQuizLearners,
  getQuestionStatusCheck,
} from '../../services/learn';
import { CenterLoadingBar, LoadingBar } from '../../components/loader/loader';
import InfiniteScrollling from '../../components/Pagination/InfiniteScrollling';
import { useSelector } from 'react-redux';
import {
  useLocation,
  useParams,
  createSearchParams,
  useSearchParams,
  NavLink,
  useNavigate,
} from 'react-router-dom';
import LearnQuizCatergoryCard from '../../components/Learn/Category/CatergoryCard';
import { QuizDetailCard } from '../../components/Learn/quizdetailCard';
import { DynamicTitle } from '../../components/dynamic_title/title';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Toast from '../../components/Common/Tostify/Toastify';

let descriptionTypes = [
  'Advance your topic learning level by answering questions. ',
  'Remember, whether correct or incorrect, you still learn and gain points. ',
  'Collect personal points (Note: this will count toward your personal score).',
  'You are limited to five questions per session, and one session per day, if you have already done today’s session, come back tomorrow! ',
];

const QuizMe = () => {
  const navigate = useNavigate();
  const authData = useSelector((state) => state.auth);
  const [searchParams] = useSearchParams();
  let topicId = searchParams.get('topic');
  const [learnDecktotalCount, setlearnDecktotalCount] = useState(0);
  const [deckLoading, setdeckLoading] = useState(false);
  const [learnDeckLists, setlearnDeckLists] = useState([]);
  const [flashCards, setFlashCards] = useState([]);
  const [learndeckStats, setlearndeckStats] = useState();
  const [flashCardId, setFlashCardId] = useState();
  const [learnDeckPage, setlearnDeckPage] = useState(0);
  const [isStartExam, setIsStartExam] = useState(false);
  const [isOpenExamPage, setOpenExamDetailPage] = useState(false);
  const [examStartDetails, setExamStartDetails] = useState([]);
  const [learnersLists, setLearnersList] = useState([]);
  const [examStart, setExamStart] = useState({});
  const [quizStatus, setQuizStatus] = useState();

  useEffect(() => {
    if (flashCardId) getFlashCards(flashCardId);
  }, [flashCardId]);

  useEffect(() => {
    if (topicId) {
      setlearnDecktotalCount(0);
      setlearndeckStats();
      setlearnDeckLists([]);
      getAllCatrgoryWiseStats();
      getQuestionStatus();
    }
  }, [topicId]);

  useEffect(() => {
    getLearnExamStartNOw();
  }, []);

  const getLearnExamStartNOw = async () => {
    try {
      let res = getLearnTakingTestNow(authData.learnerId, topicId);
      setExamStartDetails(res?.data);
    } catch (err) {}
  };

  useEffect(() => {
    getLearnBYCategory(learnDeckPage + 1);
  }, [learnDeckPage, topicId]);

  const getLearnBYCategory = async (currentPage) => {
    setdeckLoading(true);
    try {
      let res = await getTopicWiseDecks(topicId, currentPage);
      setlearnDeckLists(res?.data?.[0]?.response);
      setlearnDecktotalCount(res?.data?.[0]?.countInfo?.[0]?.count);
      setdeckLoading(false);
    } catch (err) {}
  };
  const getAllCatrgoryWiseStats = async () => {
    try {
      let res = await getTopiDetilsStats(authData.learnerId, topicId);
      setlearndeckStats(res?.data);
    } catch (err) {}
  };
  const getQuestionStatus = async () => {
    try {
      let res = await getQuestionStatusCheck(topicId);
      if (res.statusCode === 200) {
        setQuizStatus(true);
      } else {
        setQuizStatus(false);
      }
    } catch (err) {
      setQuizStatus(false);
      return err.response;
    }
  };
  const getFlashCards = async (deckId) => {
    try {
      let res = await getTopicFlashCards(deckId);
      setFlashCards(res?.data);
    } catch (err) {}
  };

  useEffect(() => {
    if (examStart?.quizMe) {
      navigate({
        pathname: `/start-learn-module-quiz`,
        search: createSearchParams({
          learn: examStart?.learnModule,
          quiz: examStart?.quizMe,
          isExp: true,
          topic: topicId,
          moduleType:'goals'
        }).toString(),
      });
    }
  }, [examStart]);
  const handleExam = async () => {
    setIsStartExam(true);
    try {
      let res = await startLearingQuizExam({
        topic: topicId,
        learner: authData.learnerId,
      });
      setExamStart(res?.data?.data);
      setIsStartExam(false);
    } catch (err) {
      setIsStartExam(false);
      Toast.error(err?.response?.data?.message);
    }
  };
  useEffect(() => {
    getQuizLearnersList();
  }, []);

  const getQuizLearnersList = async () => {
    try {
      let res = await getQuizLearners(authData.learnerId, topicId);
      res = res?.data?.length
        ? res?.data?.map((item) => ({
            profilePic: item?.learnerInfo?.profilePic,
          }))
        : [];
      setLearnersList(res);
    } catch (err) {}
  };

  return (
    <>
      <div className={`max__learn-wrapper ${'quiz_questions__bg'}`}>
        <DynamicTitle title={'Maxlearn | Learn | Topic Details'} />
        <Header />

        <QuizDetailCard
          name={authData?.fullName}
          description={descriptionTypes ?? []}
          ongoingChallenge={examStartDetails ?? []}
          handleClick={handleExam}
          isLoading={isStartExam}
          handleClose={() => navigate('/to-do?tab=quest')}
          learnersLists={[]}
        />

        <Footer />
      </div>
    </>
  );
};

export default QuizMe;
