import { initializeApp } from 'firebase/app';
import { getMessaging, getToken, onMessage } from 'firebase/messaging';
import { onBackgroundMessage } from "firebase/messaging/sw";
  
const firebaseConfig = {
    apiKey: "AIzaSyCs1n0gOhA3u084RgY4BKN81z4rxw7Uaoo",
    authDomain: "ecommerce-website-51a98.firebaseapp.com",
    projectId: "ecommerce-website-51a98",
    storageBucket: "ecommerce-website-51a98.appspot.com",
    messagingSenderId: "629543872814",
    appId: "1:629543872814:web:1f861a4370da10657bb2bf"
};

const firebaseApp = initializeApp(firebaseConfig);
const messaging = getMessaging(firebaseApp);

export const getFireBaseToken = async (setTokenFound) => {
  let currentToken = '';

  try {
    currentToken = await getToken(messaging, {
      vapidKey:
        'BM85B0BZIiEag_SO1MCcQvS9uUtC1qEcCMB4cbLLoV7KYmgM2vxENzmWlq5md0cloxdyn96OnDPAwzS77GRJLPM',
    });
  } catch (error) {
  }
  return currentToken;
};
export const onMessageListener = () =>
  new Promise((resolve) => {
    onMessage(messaging, (payload) => {
      resolve(payload);
    });
  });

export const onBackgroundMessageListener = () =>
  new Promise((resolve) => {
    onBackgroundMessage(messaging, (payload) => {
      resolve(payload);
    });
});
