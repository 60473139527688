import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

const Footer = () => {
  const { t } = useTranslation();
  return (
    <div className='max__footer-wrapper d-none d-sm-block'>
      <div className='max__footer-container'>
        <p>© 2023 Maxlearn. {t('FOOTER.ALL_RIGHTS_RESERVED')}</p>
        <div className='privacy-n-terms'>
          <p>
            <Link className='footer__link' to='/privacy_policy'>
              {t('FOOTER.PRIVACY_POLICY')}
            </Link>
            |
            <Link className='footer__link' to='/terms_conditions'>
              {t('FOOTER.TERMS')}
            </Link>
          </p>
        </div>
      </div>
    </div>
  );
};

export default Footer;
