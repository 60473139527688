import axios from 'axios';
import { toast } from 'react-toastify';
import Toast from '../components/Common/Tostify/Toastify';

const baseURL = {
  prod: '',
  //dev: 'https://yz53iah1qg.execute-api.us-east-1.amazonaws.com/local',
  //dev: 'https://1e3lg11rlk.execute-api.us-east-1.amazonaws.com/prod',
  dev: 'https://z6810aseyb.execute-api.us-east-1.amazonaws.com/uat',
};

const helpDeskURL = {
  prod: '',
  //dev: 'https://notifications.codewave.com',
  // dev: 'https://maxlearnnotifications.codewave.com',
  dev: 'https://notifications.uat.maxlearn.com',
};

//export const baseURL2 = 'https://notifications.codewave.com';
//export const baseURL2 = 'https://maxlearnnotifications.codewave.com';
export const baseURL2 = 'https://notifications.uat.maxlearn.com';

export const learnerId = localStorage.getItem('userid');

const http = axios.create({ baseURL: baseURL?.dev });

http.interceptors.request.use(
  (request) => {
    const applicationId = localStorage.getItem('applicationId');
    const token = localStorage.getItem('TOKEN_NAME');
    request.headers['x-tenant-id'] = applicationId;
    request.headers['x-access-token'] = token;
    return request;
  },
  (err) => Promise.reject(err)
);

http.interceptors.response.use(
  (response) => response,
  (error) => {
    // if (error.response.status === 404) {
    //   window.location.href = '/login';
    //   Toast.error('Session Expired ');
    // }
    return Promise.reject({ ...error });
  }
);

export default http;

export const httpNotification = axios.create({ baseURL: baseURL2 });

httpNotification.interceptors.request.use(
  (request) => {
    const applicationId = localStorage.getItem('applicationId');
    const token = localStorage.getItem('TOKEN_NAME');
    request.headers['x-tenant-id'] = applicationId;
    request.headers['x-access-token'] = token;
    return request;
  },
  (err) => Promise.reject(err)
);

export const https = axios.create({ baseURL: baseURL?.dev });

https.interceptors.request.use(
  (request) => {
    const applicationId = localStorage.getItem('applicationId');
    const token = localStorage.getItem('TOKEN_NAME');
    request.headers['x-tenant-id'] = applicationId;
    request.headers['x-access-token'] = token;
    return request;
  },
  (err) => Promise.reject(err)
);
