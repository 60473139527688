import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  loading: false,
  applicationId: localStorage.getItem('applicationId'),
  learnerId: localStorage.getItem('userid'),
  token: localStorage.getItem('TOKEN_NAME'),
  fullName: localStorage.getItem('fullname'),
  profileImage: localStorage.getItem('profileImage'),
  secretKey: '',
  dashboardLoader: false,
};

const loginSlice = createSlice({
  name: 'login',
  initialState,
  reducers: {
    saveAuth: (state, action) => {
      return {
        ...state,
        learnerId: action.payload.userid,
        token: action.payload.TOKEN_NAME,
        fullName: action.payload.fullname,
        applicationId: action.payload.applicationId,
      };
    },
    setNameLoader: (state, action) => {
      return {
        ...state,
        dashboardLoader: action?.payload,
      };
    },
    loading: (state, action) => {
      return {
        ...state,
        loading: action?.payload,
      };
    },
    userImageChange: (state, action) => {
      return { ...state, profileImage: action.payload };
    },
    savePass: (state, action) => {
      return { ...state, secretKey: action.payload };
    },
    removeAuth: () => {
      return {
        loading: null,
        applicationId: null,
        learnerId: null,
        token: null,
        fullName: null,
        profileImage: null,
        savePass: null,
      };
    },
  },
});
export const {
  saveAuth,
  userImageChange,
  loading,
  setNameLoader,
  removeAuth,
  savePass,
} = loginSlice.actions;
export default loginSlice.reducer;
