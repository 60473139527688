import axios from 'axios';
import http, { httpNotification } from '../../api/api';



export const checkForUserNotifcations = async (learnerId,page,setNotificationLoading) => {
  setNotificationLoading(true)
  try {
    const response = await http.get(
      `/api/app/notification/notifications?learnerId=${learnerId}&pageNum=${page}`
    );
    if(response.data)setNotificationLoading(false)
    return response.data;
  } catch (error) {
    setNotificationLoading(false)
    return error.response;
  }
};
export const markAsSeenNotification = async (payload) => {
    try {
      const response = await http.post(
        `/api/app/notification/mark-notifications`,payload
      );
      return response;
    } catch (error) {
      return error.response;
    }
  };
  

export const checkForNewNotifcations = async (learnerId) => {
    try {
      const response = await http.get(
        `/api/app/notification/new-notifications?learnerId=${learnerId}`
      );
      return response.data;
    } catch (error) {
      return error.response;
    }
  };

  export const getTopicForNotifcations = async (learnerId) => {
    try {
      const response = await http.get(
        `/api/app/notification/get-topics?learnerId=${learnerId}`
      );
      return response.data;
    } catch (error) {
      return error.response;
    }
  };

  export const subscribeTopicForNotifcations = async (payload) => {
    try {
      const response = await httpNotification.post(
        `/api/notification/subscribe-to-topics`,payload
      );
      return response;
    } catch (error) {
      return error.response;
    }
  };
  
  export const addFireBaseToken = async (payload) => {
    try {
      const response = await http.post(
        `/api/app/learners/set-device-token`,payload
      );
      return response;
    } catch (error) {
      return error.response;
    }
  };
