import React from 'react';
import { Link } from 'react-router-dom';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import { LearnCompliance } from '../../../assets';
import ProgressBar from '../../UI/ProgressBar';

const CatergoryCard = ({
  data,
  detail,
  level,
  button,
  topics,
  handleExam,
  openExamPage,
  decks,
  temp,
  levelStatus,
  quizStatus,
}) => {
  const colorToHex = (color) => {
    const byteToHex = (num) => {
      return ('0' + num.toString(16)).slice(-2);
    };
    const colorToRGBA = (color) => {
      var cvs, ctx;
      cvs = document.createElement('canvas');
      cvs.height = 1;
      cvs.width = 1;
      ctx = cvs.getContext('2d');
      ctx.fillStyle = color;
      ctx.fillRect(0, 0, 1, 1);
      return ctx.getImageData(0, 0, 1, 1).data;
    };
    var rgba, hex;
    rgba = colorToRGBA(color);
    hex = [0, 1, 2]
      .map(function (idx) {
        return byteToHex(rgba[idx]);
      })
      .join('');
    return '#' + hex;
  };
  const hexToRgbA = (hex) => {
    let regexp = /^#/;
    if (hex !== undefined && regexp.test(hex)) {
      var c;
      if (/^#([A-Fa-f0-9]{3}){1,2}$/.test(hex)) {
        c = hex.substring(1).split('');
        if (c.length == 3) {
          c = [c[0], c[0], c[1], c[1], c[2], c[2]];
        }
        c = '0x' + c.join('');
        return (
          'rgba(' +
          [(c >> 16) & 255, (c >> 8) & 255, c & 255].join(',') +
          ',0.6)'
        );
      }
      throw new Error('Bad Hex');
    } else if (hex !== undefined && !regexp.test(hex)) {
      return hexToRgbA(colorToHex(hex));
    }
  };
  return (
    <>
      <div
        className='max-learn__catergory-card bg-opacity-50'
        style={{ backgroundColor: hexToRgbA(data?.colorr) }}
      >
        {/* <LearnCompliance.default /> */}
        {!decks ? (
          <img src={data?.icon} style={{ width: '52px', height: '52px' }} />
        ) : (
          ''
        )}

        <h4
          className={`pt-3 ${detail ? 'detail-header-title' : ''}`}
          title={data?.title}
        >
          {data?.title?.length > 25
            ? `${data?.title?.slice(0, 25)}..`
            : data?.title}
        </h4>

        <span className='max-learn__catergory-card__category_subject'>
          {data?.count} {topics ? 'Topics' : decks ? 'Decks' : 'Subjects'}
        </span>
        <ProgressBar
          bgcolor='#212121'
          progress={data?.progress ?? 0}
          height={6}
          className='mt-2 progressbar__learn-text'
          marginTop={20}
        />

        {level === true ? (
          <>
            <div className='card_level_wrapper'>
              <img
                src='https://res.cloudinary.com/dysdy7hjr/image/upload/v1685430418/Award_eocnqe.svg'
                height='20px'
                width='20px'
              ></img>{' '}
              &nbsp;
              <span className='card_level'>
                You are at <strong>{data?.level}</strong>
              </span>
            </div>
          </>
        ) : (
          ''
        )}

        {levelStatus ? (
          <div className='card_level_wrapper mt-3'>
            <img
              src='https://res.cloudinary.com/dysdy7hjr/image/upload/v1685430438/whatsapp_status_y6jk7t.svg'
              height='20px'
              width='20px'
            ></img>{' '}
            &nbsp;
            <span className='card_level'>
              You are at <strong>{data?.levelStatus}</strong>
            </span>
          </div>
        ) : (
          ''
        )}

        <div
          className='category_detail category_detail-text-quiz'
          title={data?.description}
        >
          {detail === true && !decks
            ? data?.description?.length > 195
              ? `${data?.description?.slice(0, 195)}...`
              : data?.description
            : ''}
          {detail === true && decks == true
            ? data?.description?.length > 117
              ? `${data?.description?.slice(0, 117)}...`
              : data?.description
            : ''}
          <Tooltip title={data?.description}></Tooltip>
        </div>

        {button === true && quizStatus === true ? (
          <div
            className='card_quiz_button cursor-pointer'
            onClick={openExamPage}
          >
            <button>Quiz me</button>
          </div>
        ) : (
          ''
        )}
        <div
          className={
            detail === true
              ? 'quarter-circle detail-quarter-circle'
              : 'quarter-circle'
          }
          style={{ background: data?.colorr }}
        ></div>
      </div>
    </>
  );
};

export default CatergoryCard;
