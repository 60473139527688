import React, { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { getLearnQuizResults } from '../../services/learn';
import HighScoreCard from '../../components/Learn/score/highScore';
import LowScoreCard from '../../components/Learn/score/lowScore';
import { useSelector } from 'react-redux';
import { CenterLoadingBar } from '../../components/loader/loader';

export const QuizScore = () => {
  const authData = useSelector((state) => state.auth);
  const [searchParams] = useSearchParams();
  let learnModuleId = searchParams.get('learn');
  let quizId = searchParams.get('quiz');
  const [score, setScoreReport] = useState('');
  const [loader, setLoader] = useState(true);

  const getReport = async () => {
    try {
      const resp = await getLearnQuizResults(learnModuleId, quizId);
      if (resp.statusCode === 200) {
        setScoreReport(resp?.data?.scoreDetails);
        setLoader(false);
      }
    } catch (err) {}
  };

  useEffect(() => {
    if (quizId && learnModuleId) getReport();
  }, [quizId, learnModuleId]);
  return (
    <>
      {loader === false ? (
        <div>
          {(score?.correctAnswers / score?.totalQuestions) * 100 >= 60 ? (
            <HighScoreCard score={score} name={authData?.fullName} />
          ) : (
            <LowScoreCard score={score} name={authData?.fullName} />
          )}
        </div>
      ) : (
        <CenterLoadingBar />
      )}
    </>
  );
};
