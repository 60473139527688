import React from 'react';
import { useTranslation } from 'react-i18next';
import { EmptySearchScreen } from '../../../assets';

const EmptySearch = () => {
  const { t } = useTranslation();
  return (
    <div className='empty__search'>
      <div className='empty__search_wrapper'>
        <div>
          <EmptySearchScreen.default />
        </div>
        <div className='empty__search_content'>
          <h2>{t('EMPTY_SCREEN.SEARCH.HEADER')}</h2>
          <p>{t('EMPTY_SCREEN.SEARCH.SUB_HEADER')}</p>
        </div>
      </div>
    </div>
  );
};

export const EnterThreeSearch = () => {
  const { t } = useTranslation();
  return (
    <div className='empty__search'>
      <div className='empty__search_wrapper'>
        <div>
          <EmptySearchScreen.default />
        </div>
        <div className='empty__search_content'>
          <h2>{t('EMPTY_SCREEN.SEARCH.THREE_SEARCH')}</h2>
          {/* <p>Just try some other keywords to access the search results.</p> */}
        </div>
      </div>
    </div>
  );
};

export default EmptySearch;
