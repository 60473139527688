import React from 'react';
import Spinner from 'react-bootstrap/Spinner';

export const WaitingLoader = () => (
  <Spinner animation='border' role='status' variant='warning' />
);

export const LoadingBar = () => {
  return <div className='loading'></div>;
};

export const CenterLoadingBar = () => {
  return (
    <div className='loading__wrapper'>
      <div className='loading'></div>
    </div>
  );
};

export const ButtonLoader = () => {
  return (
    <div className='loader__container'>
      <span></span>
      <span></span>
      <span></span>
      <span></span>
    </div>
  );
};

export const OutlineButtonLoader = () => {
  return (
    <div className='loader__orange'>
      <span></span>
      <span></span>
      <span></span>
      <span></span>
    </div>
  );
};

export const SkeletonCardLoader = () => {
  return (
    <div className='loading_card'>
      <div className='loading_card-content'></div>
    </div>
  );
};

export const UserCardLoader = () => {
  return (
    <div className='user_card--wrapper'>
      <div className='user_card--content'></div>
    </div>
  );
};

export const NavIconsLoader = () => {
  return (
    <div className='nav_card--wrapper'>
      <div className='nav_card--content'></div>
    </div>
  );
};
