import React from 'react';
import { NavToDoLogo } from '../../assets';
import Badge from '@mui/material/Badge';
import moment from 'moment';

const badgeStyle = {
  '& .MuiBadge-badge': {
    height: '12px',
    width: '12px',
    border: ' 1px solid #F0F0F0',
    background: 'linear-gradient(159.78deg, #009FBC 0%, #00BA88 100%)',
    borderRadius: '50%',
    right: '-6px',
    top: '-6px',
  },
};

// classname for all the color for the notification
const colorClassName = {
  purple: 'purple',
  yellow: 'yellow',
  green: 'green',
  pink: 'pink',
};

const CardData = ({ data, handleNotificationNavigation }) => {
  console.log(data, 'sdklfjklsdjfsdl');
  return (
    <div
      className='row w-100 d-flex align-items-center flex-column'
      onClick={() => handleNotificationNavigation(data)}
    >
      <div className='row w-100 d-flex align-items-center'>
        <div
          className={`col-2 icon_wrapper `}
          style={{ backgroundColor: data?.data?.backgroundColor }}
        >
          {/* <NavToDoLogo.default /> */}
          <img src={data?.data?.url} alt='' />
        </div>
        <div className='col-10'>
          <div className='card_title' title={data?.content}>
            {data?.content?.length > 32
              ? `${data?.content?.slice(0, 79)}${
                  data?.content > 79 ? '...' : ''
                }`
              : data?.content}
          </div>
        </div>
      </div>
      <div className='col-12 notification_time'>
        <div>{moment(data?.createdAt).fromNow()}</div>
      </div>
    </div>
  );
};

const CardModalNotification = ({
  data,
  index,
  handleNotificationNavigation,
}) => {
  return (
    <div
      className={`notification_card_wrapper ${'yellow'}`}
      style={{ cursor: 'pointer' }}
    >
      {!data?.isRead ? (
        <Badge sx={badgeStyle} variant='dot' className='w-100'>
          <CardData
            data={data}
            handleNotificationNavigation={handleNotificationNavigation}
          />
        </Badge>
      ) : (
        <CardData
          data={data}
          handleNotificationNavigation={handleNotificationNavigation}
        />
      )}
    </div>
  );
};

export default CardModalNotification;
