import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  rank: '',
  points: '',
  name: '',
  profileImage: '',
  onTarget:false
};

const learnerDetailSlice = createSlice({
  name: 'learnerDetail',
  initialState,
  reducers: {
    saveLearnerDetails: (state, action) => {
      return {
        ...state,
        rank: action?.payload?.rank || 0,
        points: action?.payload?.points,
        onTarget: action?.payload.onTarget,
      };
    },
    savebasicInfo: (state, action) => {
      return {
        ...state,
        name: action?.payload?.name,
        profileImage: action?.payload?.profilePic,
        onTarget: action?.payload?.onTarget,
      };
    },
    changePic: (state, action) => {
      return { ...state, profileImage: action.payload };
    },
    removeLearnerDetails: () => {
      return {
        rank: '',
        points: '',
        name: '',
        profileImage: '',
        onTarget:''
      };
    },
  },
});
export const {
  saveLearnerDetails,
  savebasicInfo,
  changePic,
  removeLearnerDetails,
} = learnerDetailSlice.actions;
export default learnerDetailSlice.reducer;
