import React from 'react';
import CatergoryCard from './CatergoryCard';
import { Link } from 'react-router-dom';
import BreadCrumb from '../../Common/BreadCrumb/BreadCrumb';
import { TopicCardData } from './data';
import TopicCard from './TopicCard';
import InfiniteScrollling from '../../Pagination/InfiniteScrollling';
import { CenterLoadingBar, LoadingBar } from '../../loader/loader';
import { useTranslation } from 'react-i18next';

const TopicCategoryDetail = ({
  learndeckStats,
  learnDeckLists,
  count,
  setlearnDeckPage,
  learnDeckPage,
  deckLoading,
  setFlashCardId,
  handleExam,
  openExamPage,
  topicId,
  quizStatus,
}) => {
  const { t } = useTranslation();
  return (
    <>
      <div className='container category_detail_wrapper vh-100'>
        {learndeckStats?.category ? (
          <BreadCrumb
            title='Topic detail page'
            id={5}
            params={{
              category: learndeckStats?.category,
              subject: learndeckStats?.subject,
              topic: topicId,
            }}
          />
        ) : (
          <p className='list_bread'></p>
        )}

        <div className='row detail_row'>
          <div
            className={
              quizStatus === true
                ? 'col-xl-3 col-lg-4 col-12  detail_left_col detail_left_col-h'
                : 'col-xl-3 col-lg-4 col-12  detail_left_col '
            }
          >
            {deckLoading ? (
              <CenterLoadingBar />
            ) : (
              <CatergoryCard
                data={{
                  progress: learndeckStats?.progressPercent,
                  title: learndeckStats?.title,
                  description: learndeckStats?.description,
                  count: learndeckStats?.decksCount,
                  level: learndeckStats?.learningLevel,
                  colorr: learndeckStats?.categoryColor,
                  levelStatus: learndeckStats?.levelStatus,
                }}
                detail={true}
                level={true}
                button={true}
                handleExam={handleExam}
                openExamPage={openExamPage}
                decks={true}
                temp={true}
                levelStatus={true}
                quizStatus={quizStatus}
              />
            )}
          </div>
          {/* );
          })} */}

          <div className='col-xl-8 col-lg-8 col-12 detail_right_col'>
            <div className='subject_description' title={learndeckStats?.title}>
              {t('LEARN.DECKS_UNDER')}
              {learndeckStats?.title?.length > 25
                ? `${learndeckStats?.title?.slice(0, 25)}...`
                : learndeckStats?.title}{' '}
              ({count ?? 0})
            </div>

            {deckLoading && learnDeckPage === 0 ? (
              <CenterLoadingBar />
            ) : (
              <InfiniteScrollling
                dataLength={learnDeckLists?.length}
                next={() => setlearnDeckPage(learnDeckPage + 1)}
                hasMore={learnDeckLists?.length < count}
                loader={<LoadingBar />}
              >
                <div className='row'>
                  {learnDeckLists?.length
                    ? learnDeckLists.map((data, i) => {
                        return (
                          <div
                            className='col-xl-4 col-lg-6 col-md-6 col-sm-12 col-12 mb-3'
                            key={i}
                          >
                            <Link to={`/learn/flash-card?deck=${data?._id}`}>
                              <TopicCard data={data} />
                            </Link>
                          </div>
                        );
                      })
                    : ''}
                </div>
              </InfiniteScrollling>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default TopicCategoryDetail;
