import React from 'react';
import { Breadcrumbs } from '@mui/material';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

const BreadCrumb = ({ title, id, path, params }) => {
  const { t } = useTranslation();
  const dummyData = [
    {
      path: '/learn',
      title: t('LEARN.LEARN'),
      active: 'list_bread__key2',
      inActive: 'list_bread__key1',
    },
    {
      path: '/learn/category-list',
      title: t('LEARN.CATEGORY_LISTING'),
      active: 'list_bread__key2',
      inActive: 'list_bread__key1',
    },
    {
      path: `/learn/detail?category=${params?.category}`,
      title: t('LEARN.CATEGORY_DETAIL_PAGE'),
      active: 'list_bread__key2',
      inActive: 'list_bread__key1',
    },
    {
      path: `/learn/subject-detail?subject=${params?.subject}`,
      title: t('LEARN.SUBJECT_DETAIL_PAGE'),
      active: 'list_bread__key2',
      inActive: 'list_bread__key1',
    },
    {
      path: `/learn/topic-detail?topic=${params?.topic}`,
      title: t('LEARN.TOPIC_DETAIL_PAGE'),
      active: 'list_bread__key2',
      inActive: 'list_bread__key1',
    },
  ];

  return (
    <div className='list_bread'>
      <Breadcrumbs separator='|' aria-label='breadcrumb'>
        {dummyData.slice(0, id).map((val, i) => {
          return (
            <Link
              to={val.path}
              className={title === val.title ? val.active : val.inActive}
              key={i}
            >
              {val.title}
            </Link>
          );
        })}
      </Breadcrumbs>
    </div>
  );
  //   });
};

export default BreadCrumb;
