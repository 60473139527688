import http from '../../api/api';

export const CurrentandUpcommingChallenge = async (learnerId, query) => {
  const response = await http.get(
    `/api/app/challenges/current-and-upcoming-challenges/${learnerId}?`,
    {
      params: query,
    }
  );
  return response.data;
};

export const TodayandUpcomingCertQuest = async (learnerId, query) => {
  const response = await http.get(`/api/app/certs/list/${learnerId}`, {
    params: query,
  });
  return response.data;
};

export const universalSearch = async (text) => {
  const response = await http.get(
    `/api/app/learn-module/search?searchText=${text}`
  );
  return response.data;
};

export const getFolderId = async () => {
  const response = await http.get(
    `/api/app/freshDesk/get-categories-folder?category=banner`
  );
  return response.data;
};

export const getDiduKnow = async () => {
  const response = await http.get(`/api/admin/banner/get-all?sendAll=false`);
  return response.data;
};

export const getBannerImages = async () => {
  const response = await http.get(`/api/admin/banner/get-banner-images`);
  return response.data;
};
