import React from 'react';

const TopicCard = ({ data }) => {
  return (
    <div className='topic-card-wrapper'>
      <div className='card-name' title={data?.title}>
        {data?.title?.length > 20
          ? `${data?.title?.slice(0, 20)}...`
          : data?.title}
      </div>
      <div className='card-number'>{data.flashcardCount} Flash Cards</div>
    </div>
  );
};

export default TopicCard;
