import axios from '../../api/api';

export const getCertBasedOnLearnerId = (
  learnerID,
  upcoming,
  pageNum,
  ongoing
) => {
  let query = {};
  if (upcoming) query = { ...query, upcoming };
  if (ongoing) query = { ...query, ongoing };
  if (pageNum) query = { ...query, pageNum };

  return axios.get(`/api/app/certs/list/${learnerID}`, { params: query });
};

export const startCertExam = (obj) =>
  axios.post('/api/app/certs/start-cert', obj);

export const resumeCertExam = (obj) =>
  axios.post('/api/app/certs/resume-cert', obj);

export const getCertExamQuestions = (certId) =>
  axios.post('/api/app/certs/questions-from-cert', { certId });

export const submitCertExamAns = (payload) =>
  axios.post('/api/app/certs/submit-answer', payload);

export const fetchCertById = (learnerId, certId) =>
  axios.post('/api/app/certs/get-cert-by-id', { certId, learnerId });

export const finishCertTest = (payload) =>
  axios.post('/api/app/certs/finish-cert-test', payload);

export const fetchCertReport = (resultId) =>
  axios.post('/api/app/certs/get-cert-report', { resultId });
